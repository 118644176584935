.parallaxParent {
  position: fixed;
  height: 200vh;
  width: 100%;
  overflow: hidden !important;
  background: black;
}


.hero-container {
  height: 200vh;
}

.hero-text-container {
  position: absolute;
  top: 80vh;
  width: 100%;
  text-align: center;
  line-height: 1.4em;

  font-weight: 100;
  font-size: 10rem;
  color: rgba(255, 255, 255, 0.7);

  @include respond-to("desktop-13"){
    font-size: 8rem;
    font-weight: 200;
  }

  @include respond-to("tablet-large"){
    font-size: 6rem;
    font-weight: 300;
  }

  @include respond-to("tablet"){
    font-size: 6rem;
    font-weight: 300;
  }

  @include respond-to("mobile-large"){
    font-size: 5rem;
    font-weight: 300;
  }
}

#hero-canvas {
  width: 100%;
  height: 100%;
  background: url("./assets/images/background.png");
  background-position: left 52% top 3%;
  background-size: auto 120%;
}
