$white: #fff;

$light-grey: #fafafa;
$grey: #d7d7d7;

$black-soft: #444;
$black: #09090e;

$blue: #3983ef;
$pink: #fff0f5;
$yellow: #fffacd;


$color-primary: $blue;
$color-secondary: $pink;
$color-tertiary: $yellow;
