#section-projects {
  padding: 15vh 0;
  background: #4c3b70;
  color: #ffffffdd;
}

.job-description {
  margin: 4ch 0 5ch;

  @include respond-to('tablet') {
    margin: 3ch 0 4ch;
  }

  @include respond-to('mobile-large') {
    margin: 2ch 0 3ch;
  }
}
