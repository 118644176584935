html {
  scroll-behavior: smooth;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
}

.wrapper {
  max-width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0;
}

a {
  text-decoration: none;
  color: $blue;
}

img {
  display: block;
}

ul {
  padding: 0;
  list-style: none;
}

b {
  font-weight: bold;
}

a.anchor {
  display: block;
  position: relative;
  top: -60px;
  visibility: hidden;
}

.auto-width {
  max-width: 1024px;
  margin: 0 auto;

  @include respond-to('desktop-13') {
    padding: 0 35px;
  }

  @include respond-to('tablet') {
    padding: 0 30px;
  }

  @include respond-to('mobile-large') {
    padding: 0 20px;
  }
}

.obscurify {
  font-size: 15px;
  font-family: monospace;
  position: relative;

  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
.obscurify > span {
  position: absolute;
}

.main-content {
  position: relative;
  background: white;
}

.noselect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

footer {
  padding: 10px 20px;

  a {
    color: inherit;
    text-decoration: underline;
  }

  @include respond-to('tablet') {
    .column {
      text-align: center !important;
      padding-top: 2px;
    }
  }
}

h1 {
  font-size: 4em;
  font-weight: 400;

  border-bottom: 0.1ch solid;

  @include respond-to('desktop-13') {
    font-size: 3em;
  }

  @include respond-to('mobile-large') {
    font-size: 2.7em;
  }
}

h2 {
  @include respond-to('desktop-13') {
    font-size: 2.5em;
  }

  @include respond-to('mobile-large') {
    font-size: 1.8em;
  }
}

h3 {
  @include respond-to('tablet') {
    font-size: 1.5em;
  }

  @include respond-to('mobile-large') {
    font-size: 1.3em;
  }
}

