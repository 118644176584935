.tech-container {
  $icon-size: 17ch;

  display: inline-block;
  width: $icon-size;
  margin: 10px;

  .tech-icon {
    height: $icon-size;
    width: $icon-size;
  }

  .tech-label {
    width: 100%;
    text-align: center;
  }
}

#section-techs {
  padding-bottom: 30vh;
  overflow-x: hidden;
}

#tech-headline {
  padding-top: 1.5ch;
  margin-bottom: -3ch;
}

.tech-divider {
  display: block;
  height: 10ch;

  @include respond-to("desktop-13"){
    height: 6ch;
  }
}

.tech-subtitle {
  display: block;
  width: 100%;
  margin: 1ch 0 1ch;
  padding: 0 4ch;

  @include respond-to("desktop-13"){
    padding: 0 1.5ch;
    margin: 1ch 0 0.3ch;
  }

  @include respond-to("mobile-large"){
    margin: 0.7ch 0 0.3ch;
    padding: 0;
  }
}
