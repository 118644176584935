#section-about {
  padding: 30vh 0 35vh;
  background: #f5c63d;

  color: #000000CC;
}

.about-text {
  line-height: 1.7em;
}
