@font-face {
  font-family: 'Barlow';
  font-display: fallback;
  font-style: normal;
  font-weight: 100;
  src: local('Barlow Thin'), local('Barlow-Thin'),
    url('./assets/fonts/Barlow-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-display: fallback;
  font-style: normal;
  font-weight: 300;
  src: local('Barlow Light'), local('Barlow-Light'),
  url('./assets/fonts/Barlow-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-display: fallback;
  font-style: normal;
  font-weight: 400;
  src: local('Barlow'), local('Barlow'),
  url('./assets/fonts/Barlow-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Barlow';
  font-display: fallback;
  font-style: normal;
  font-weight: 700;
  src: local('Barlow Bold'), local('Barlow-Bold'),
  url('./assets/fonts/Barlow-SemiBold.ttf') format('truetype');
}

body {
  font-family: 'Barlow', sans-serif;
  font-size: 1.6rem;
  color: $black;
}

