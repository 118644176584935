#section-contact {
  padding: 30vh 0 25vh;
}

.contact-container {
  margin: 20vh 0;
  text-align: center;
}

.contact-caption {
  padding-top: 15vh;
  font-size: 3em;
}

#contact-description {
  line-height: 1.5em;
  font-size: 2.8em;
  opacity: 0;
  transition: opacity 0.2s ease-out;

  @include respond-to('desktop-13') {
    font-size: 2.4em;
  }

  @include respond-to('tablet') {
    font-size: 2em;
  }

  @include respond-to('mobile-large') {
    font-size: 1.6em;
  }
}

#contact-description.active {
  opacity: 1;
  transition: opacity 0.5s ease-in;
  transition-delay: 0.5s;
}

#email-container {
  display: block;
  padding-top: 33vh;

  text-align: center;
  font-weight: 300;
  overflow: hidden;

  a.ripple {
    color: $color-primary;

    overflow: hidden;
    background: transparent
      radial-gradient(circle, transparent 0%, transparent 0%) center;
    background-size: 0;
    transition: background 0s, letter-spacing 0.23s;
  }

  a.ripple.active {
    color: white;
    background: transparent
      radial-gradient(circle, $color-primary 1%, transparent 1%) center/15000%;
    background-size: 12000%;
    transition: background 0.5s, color 0.3s, letter-spacing 0.23s;
  }

  a.ripple.active:hover {
    letter-spacing: 2px;
  }

  #email-prefix {
    font-size: 8.2rem;
    display: inline-block;

    @include respond-to('desktop-13') {
      font-size: 8rem;
    }

    @include respond-to('tablet') {
      font-size: 5rem;
    }

    @include respond-to('mobile-large') {
      font-size: 4rem;
    }
  }

  #email-tld {
    margin-left: 0;
    font-size: 8rem;
    display: inline-block;

    @include respond-to('desktop-13') {
      font-size: 7.5rem;
    }

    @include respond-to('tablet') {
      font-size: 5rem;
    }

    @include respond-to('mobile-large') {
      font-size: 3.5rem;
    }
  }

  #email-label {
    display: block;
    padding: 20px 0 30px;
    font-size: 0;
    font-weight: 400;
  }
}

#contact-subtitle {
  width: 100%;
  text-align: center;
  font-size: 12rem;
  font-weight: 400;

  @include respond-to('tablet') {
    font-size: 4em;
  }

  @include respond-to('mobile-large') {
    font-size: 3em;
  }
}
