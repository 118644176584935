.nav {
  position: -webkit-sticky;
  position: sticky;
  top: 0;

  backdrop-filter: blur(6px);
  background-color: rgba(250, 250, 250, 0.9);
  font-size: 20px;

  z-index: 100;

  @include respond-to("tablet"){
    display: none;
  }

  .nav-body {
    margin: 0 auto;
    max-width: 1024px;

    a {
      color: black;
    }

    a:hover {
      color: $color-primary;
    }

    .brand {
      font-weight: bold;
      padding: 15px;

      a {
        letter-spacing: 1px;
      }
    }

    .nav-link {
      position: relative;
      display: inline-block;
      bottom: -2px;
      padding: 11px;
      border: 4px solid transparent;
    }

    .nav-link.active {
      color: $color-primary;
      border-bottom-color: $color-primary;
    }

    #link-start {
      font-size: 25px;
      padding: 5px 15px;
      vertical-align: sub;
    }

    #link-start > * {
      transition: transform 0.7s ease-out;

      transform: rotate(-45deg);
    }

    #link-start.active > * {
      transform: rotate(0deg);
    }

    .nav-link-container {
      padding-right: 10px;
    }
  }
}

.placeholder-box {
  position: absolute;
  height: 65px;
  width: 100%;
  background:white;
}
